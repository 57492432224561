const OPTIMIZELY_FULLSTACK = {
  localhost: 'QZBQbZ3wn9x9mjFdZxPsPP',
  development: 'KTXFzcMHJYY5A4VA81D5T',
  staging: 'Y631vP3eFfcy9TGhBCaodX',
  production: '47sAJkret1FpH9TRioXYE4',
}
const getOptimizelyKey = ({ env = process.env.NEXT_PUBLIC_ETHOS_ENV } = {}) => {
  switch (env) {
    case 'localhost': {
      return OPTIMIZELY_FULLSTACK.localhost
    }

    case 'development': {
      return OPTIMIZELY_FULLSTACK.development
    }

    case 'netlify':
    case 'staging': {
      return OPTIMIZELY_FULLSTACK.staging
    }

    default: {
      return OPTIMIZELY_FULLSTACK.production
    }
  }
}

exports.getOptimizelyKey = getOptimizelyKey

exports.OPTIMIZELY_FULLSTACK = OPTIMIZELY_FULLSTACK
